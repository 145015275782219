var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subHeaderText") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"headingLevel") : depth0), depth0))
    + " data-locator=\"welcomeTextWithIataHeading\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subHeaderText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":77},"end":{"line":4,"column":136}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"headingLevel") : depth0),"neq","h1",{"name":"if-cond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":137},"end":{"line":4,"column":206}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0), depth0)) != null ? stack1 : "")
    + "</"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"headingLevel") : depth0), depth0))
    + ">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " l-margin-bottom-five-quarters ";
},"5":function(container,depth0,helpers,partials,data) {
    return "t-extend-h3 t-font-ml";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <p class=\"t-font-family-proximanova "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLargeFont") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":82}}})) != null ? stack1 : "")
    + "\">\r\n               <span data-locator=\"iataLabel\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewLine") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":8,"column":53},"end":{"line":8,"column":126}}})) != null ? stack1 : "")
    + " l-m-display-inline-block l-margin-bottom-half\"> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subHeaderText") : depth0), depth0))
    + " </span>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iataNumber") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "         </p>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " t-font-ml ";
},"10":function(container,depth0,helpers,partials,data) {
    return " l-display-block ";
},"12":function(container,depth0,helpers,partials,data) {
    return " l-display-inline-block ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <span data-locator=\"iataNumberLabel\" class=\"t-font-weight-semibold "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isNewLine") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":10,"column":82},"end":{"line":10,"column":155}}})) != null ? stack1 : "")
    + " l-m-display-inline-block\"> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"iataNumber") : depth0), depth0))
    + " </span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile-agencyInfo-withIata\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showSection") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "</div>\r\n";
},"useData":true});