/* Component Dependencies */
var agencyInfoTemplate = require('templates/agencyInfo.hbs');
var agencyInfoWithIataTemplate = require('templates/agencyInfo-withIata.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'agencyInfo',
  template: {
    'agencyInfo': agencyInfoTemplate,
    'agencyInfo-withIata': agencyInfoWithIataTemplate
  }
});
